import kotlin.js.Json
import kotlin.reflect.KClass

enum class ProcessMode(val rawValue: String) {
	VALUE("value"),
	COMMAND("command")
}

// 行き
data class LintArtery(
		val process_mode: ProcessMode,
		val constants: Map<Int, Map<String, LintVein>>
)

// 帰り
data class LintVein(
		val value_type: KClass<*>,
		val integer_range: IntRange? = null
)

object Linter {
	
	var is_all_valid: Boolean = true
	
	class Invalidness(
			val arg_name: String?,
			val type: Type,
			val reason: String) {
		
		enum class Type(val rawValue: String) {
			ARG_EMPTY("arg-empty"),
			ARG_TYPE("arg-type"),
			ARG_VALUE("arg-value"),
			JSON_FORMAT("json-format"),
		}
	}
	
	@JsName("validateChunks")
	fun validateChunks(artboard: Array<Json>): Boolean {
		this.is_all_valid = true
		
		artboard.forEach {
			val json = if(it["func_name"] == "start") it["joint"] as? Json else it
			validateChunk(json, LintArtery(ProcessMode.COMMAND, mapOf()))
		}
		
		return this.is_all_valid
	}
	
	fun validateChunk(chunk_json: Json?, artery: LintArtery): LintVein? {
		
		if(chunk_json === null) {
			this.is_all_valid = false
//		    push_invalid(Invalidness(path, Invalidness.Type.JSON_FORMAT, "chunkがnullです"))
			return null
		}
		
		chunk_json["invalids"] = arrayOf<Invalidness>();
		
		val push_invalid = {
			invalidness: Invalidness ->
			val array = (chunk_json["invalids"] as Array<Invalidness>).toMutableList()
			array += invalidness
			chunk_json["invalids"] = array.toTypedArray()
			this.is_all_valid = false
		}
		
		chunk_json["process_mode"] = artery.process_mode.rawValue
		
		val chunk_type = chunk_json["chunk_type"] as? String
		if(chunk_type == null) {
			push_invalid(Invalidness(null, Invalidness.Type.JSON_FORMAT, "chunk_typeがString型じゃありません"))
			return null
		}
		
		when(chunk_type) {
			
			// 関数型チャンク
			"function" -> {
				
				val func_name = chunk_json["func_name"] as? String
				if(func_name == null) {
					push_invalid(Invalidness(null, Invalidness.Type.JSON_FORMAT, "func_nameがString型じゃありません"))
				}
				
				val chunk_func = chunk_functions.find { it.func_name === func_name }
				if(chunk_func === null) {
					push_invalid(Invalidness(null, Invalidness.Type.JSON_FORMAT, "関数「${func_name}」が見つかりません"))
				}
				
				val key = chunk_json["key"] as? Int
				if(key == null) {
					push_invalid(Invalidness(null, Invalidness.Type.JSON_FORMAT, "keyが設定されていません"))
				}
				
				val args_json = chunk_json["args"] as? Json
				if(args_json == null) {
					push_invalid(Invalidness(null, Invalidness.Type.JSON_FORMAT, "argsがJson型じゃありません"))
					return null
				}
				
				// 必要な引数
				val require_args = if(chunk_func !== null) {
					chunk_func.get_required_args()
				} else if(args_json == null) {
					arrayOf<String>()
				} else {
					// 既にセットされているものも一応出す（エディター側で削除できるようにするため）
					val arg_names = js("Object").keys(args_json) as Array<String>
					arg_names.map { ChunkFunction.RequireInputs(it, "UNDEFINED") }.toTypedArray()
				}
				
				chunk_json["require_args"] = require_args
				
				if (chunk_func === null || key === null) {
					return null
				}
				
				val args = chunk_func.inputs_structure.map {
					val arg_name = it.first
					val chunk_json = args_json[arg_name] as? Json
					val new_artery = artery.copy(process_mode = ProcessMode.VALUE)
					val vein = validateChunk(chunk_json, new_artery)
					Pair(arg_name, vein)
				}.toMap()
				
				when(artery.process_mode) {
					ProcessMode.VALUE -> {
						val value = chunk_func.validate(args, push_invalid)
						return value
					}
					
					ProcessMode.COMMAND -> {
						
						// 定数を保存
						val new_constants = artery.constants.toMutableMap()
						val branches: MutableMap<String, LintVein> = mutableMapOf()
						chunk_func.outputs_structure.forEach {
							(name) ->
							val vein = chunk_func.validate(args, push_invalid)  // FIX: 一時的にどのブランチも同じ値にする
							vein?.let { branches.set(name, vein) }
						}
						new_constants.set(key, branches)
						
						// エディター用
						chunk_json["constants"] = chunk_func.outputs_structure.map {
							(name) ->
							object {
								val origin_key = key
								val branch = arrayOf(name)
							}
						}.toTypedArray()
						
						chunk_json["is_accept_scope"] = chunk_func.is_accept_scope
						val scope = chunk_json["scope"] as? Json
						scope?.let {
							val new_artery = artery.copy(process_mode = ProcessMode.COMMAND, constants = new_constants)
							validateChunk(it, new_artery)
						}
						
						val joint = chunk_json["joint"] as? Json
						joint?.let {
							val new_artery = artery.copy(process_mode = ProcessMode.COMMAND, constants = new_constants)
							validateChunk(it, new_artery)
						}
						
						return null
					}
				}
			}
			
			// リテラル型チャンク
			"literal" -> {
				
				val literal_type = chunk_json["literal_type"] as? String
				if(literal_type == null) {
					push_invalid(Invalidness(null, Invalidness.Type.JSON_FORMAT, "literal_typeがString型じゃありません"))
					return null
				}
				
				val override = chunk_json["override"] as? Json
				override?.let {
					val new_artery = artery.copy(process_mode = ProcessMode.VALUE)
					return validateChunk(it, new_artery)
				}
				
				val type = when(literal_type) {
					"INTEGER" -> BPInteger::class
					"COLOR" -> BPColor::class
					"ANGLE" -> BPAngle::class
					else -> throw Exception("literalに予期せぬ型が渡されました")
				}
				
				val value = chunk_json["value"] as? Int
				if(value == null) {
					push_invalid(Invalidness(null, Invalidness.Type.JSON_FORMAT, "literal_type「${literal_type}」のvalueがInt型じゃありません"))
					return null
				}
				
				return when(type) {
					BPInteger::class -> LintVein(value_type = type, integer_range = value..value)
					BPColor::class -> LintVein(value_type = type)
					BPAngle::class -> LintVein(value_type = type)
					
					else -> {
						push_invalid(Invalidness(null, Invalidness.Type.JSON_FORMAT, "不明なliteral_type「${literal_type}」です"))
						return null
					}
				}
			}
			
			// 定数型チャンク
			"constant" -> {
				
				val key = chunk_json["key"] as? Int
				
				if(key == null) {
					push_invalid(Invalidness(null, Invalidness.Type.JSON_FORMAT, "keyが設定されていません"))
					return null
				}
				
				val origin_key = chunk_json["origin_key"] as? Int
				if(origin_key == null) {
					push_invalid(Invalidness(null, Invalidness.Type.JSON_FORMAT, "origin_keyがInt型じゃありません"))
					return null
				}
				
				val branches = artery.constants[origin_key]
				if(branches == null) {
					push_invalid(Invalidness(null, Invalidness.Type.JSON_FORMAT, "定数チャンクのが参照先が見つかりません"))
					return null
				}
				
				val branch = chunk_json["branch"] as? Array<String>
				val value_name = branch?.firstOrNull()
				if(value_name == null) {
					push_invalid(Invalidness(null, Invalidness.Type.JSON_FORMAT, "定数チャンクのが参照先ブランチが指定されていません"))
					return null
				}
				
				val origin_vein = branches[value_name]
				if(origin_vein == null) {
					push_invalid(Invalidness(null, Invalidness.Type.JSON_FORMAT, "定数チャンクのが参照先ブランチが見つかりません"))
					return null
				}
				
				return origin_vein
			}
		}
		
		push_invalid(Invalidness(null, Invalidness.Type.JSON_FORMAT, "不明なchunk_type「${chunk_type}」です"))
		return null
	}
}
