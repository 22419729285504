
import kotlin.js.Math

class Blur {
	val min: Double
	val max: Double
	val isInteger: Boolean   // 整数 ３〜５ か 実数 3.0〜5.0 か
	
	/* 3パターン
	Blur(min = 20.0, max = 23.0)     -> 17.0 <= x < 23.0
	Blur(center = 20.0, radius = 3.0) -> 17.0 <= x < 23.0
	Blur(center = 30.0) -> x = 30.0
	Blur(radius = 4.0) -> -4.0 <= x < 4.0
	*/
	constructor(min: Double? = null, max: Double? = null, center: Double? = null, radius: Double? = null) {
		this.isInteger = false
		
		if(min != null) {
			this.min = min!!
			this.max = max!!
		} else {
			this.min = (center ?: 0.0) - (radius ?: 0.0)
			this.max = (center ?: 0.0) + (radius ?: 0.0)
		}
	}
	
	constructor(min: Int? = null, max: Int? = null, center: Int? = null, radius: Int? = null) {
		this.isInteger = true
		
		if(min != null) {
			this.min = min!!.toDouble()
			this.max = max!!.toDouble()
		} else {
			this.min = (center?.toDouble() ?: 0.0) - (radius?.toDouble() ?: 0.0)
			this.max = (center?.toDouble() ?: 0.0) + (radius?.toDouble() ?: 0.0)
		}
	}
	
	fun toDouble(): Double {
		return min + (max - min) * Math.random()
	}
	
	fun toInt(): Int {
		return Math.floor(min + (max - min + 1.0) * Math.random())
	}
	
	fun average(): Double {
		return (min + max) / 2
	}
}

// 演算子（Blur側の拡張）
operator fun Blur.plus(num: Double): Double {
	return this.toDouble() + num
}

operator fun Blur.minus(num: Double): Double {
	return this.toDouble() - num
}

operator fun Blur.times(num: Double): Double {
	return this.toDouble() * num
}

operator fun Blur.div(num: Double): Double {
	return this.toDouble() / num
}

// 演算子（Double側の拡張）
operator fun Double.plus(blur: Blur): Double {
	return this + blur.toDouble()
}

operator fun Double.minus(blur: Blur): Double {
	return this - blur.toDouble()
}

operator fun Double.times(blur: Blur): Double {
	return this * blur.toDouble()
}

operator fun Double.div(blur: Blur): Double {
	return this / blur.toDouble()
}