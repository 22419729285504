
import kotlin.js.Math

val RANDOM_ANGLE = Blur(center = 0.0, radius = Math.PI * 2)

val ANGLE_RIGHT  = Math.PI*2 * 0/4
val ANGLE_TOP    = Math.PI*2 * 1/4
val ANGLE_LEFT   = Math.PI*2 * 2/4
val ANGLE_BOTTOM = Math.PI*2 * 3/4

val GOLDEN_RATIO = 1.6180339887

fun main(args: Array<String>) {

}
