
import kotlin.js.Math

class Angle {
	var radian: Double
	
	companion object {
		val zero = Vector(x = 0.0, y = 0.0)
	}
	
	constructor(rad: Double? = null, frac: Double? = null) {
		when {
			rad != null -> this.radian = rad!!
			frac != null -> this.radian = Math.PI*2 * frac!!
			else -> throw Error("Angle()の引数を指定してください")
		}
	}
	
	fun opposite(): Angle {
		return Angle(rad = this.radian + Math.PI)
	}
	
	// 度数法に変換
	fun toDegree(): Double {
		return this.radian * 180 / Math.PI
	}
	
	fun toFraction(): Double {
		return this.radian / Math.PI / 2
	}
	
	fun toRadian(): Double {
		return this.radian
	}
	
	// Operators
	operator fun plus(given: Angle): Angle {
		return Angle(rad = this.radian + given.radian)
	}
	
	operator fun minus(given: Angle): Angle {
		return Angle(rad = this.radian - given.radian)
	}
	
	override operator fun equals(given: Any?): Boolean {
		if(given == null || given !is Angle) return false
		return this.radian == given.radian
	}
}
