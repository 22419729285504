import kotlin.js.Json
import kotlin.reflect.KClass

typealias BPInteger = Int
typealias BPString = String
typealias BPVector = Vector
typealias BPRealnum = Double
class BPDraw

class BPAngle(
		val value: Int  // 0 <= x < 360
) {
	
	// Operators
	operator fun plus(given: BPAngle): BPAngle {
		return BPAngle(value = this.value + given.value)
	}
	
	operator fun minus(given: BPAngle): BPAngle {
		return BPAngle(value = this.value - given.value)
	}
}

class BPColor(
		val value: Int  // 0 or 1
) {
	
	companion object {
		//		val white = "#ededed"	// (red:237, green:237, blue:237)
//		val black = "#57385c"	// (red:87, green:56, blue:92)
		val white = "#ededed"	// (red:237, green:237, blue:237)
		val black = "#3b3b3b"	// (red:87, green:56, blue:92)
	}
	
	val hex_color: String
		get() = if(value.isEven()) white else black
}


// 微乱模様のチャンクが返す値
class BPValue(val value: Any)

fun get_BPValue_class_name(kClass: KClass<*>): String {
	return when(kClass) {
		BPInteger::class -> "INTEGER"
		BPString::class -> "STRING"
		BPVector::class -> "VECTOR"
		BPDraw::class -> "DRAW"
		BPPath::class -> "FIGURE"
		BPRealnum::class -> "REALNUM"
		BPAngle::class -> "ANGLE"
		BPColor::class -> "COLOR"
		else -> throw Exception("get_BPValue_class_name(): 不明な型「(${kClass.js.name}) 」が指定されています")
	}
}
