
import org.w3c.dom.CanvasRenderingContext2D
import kotlin.js.Math

/*
純粋な図形
Blurなどは使わない。
 */

interface BPPath {
	fun fill(context: CanvasRenderingContext2D)
	fun stroke(context: CanvasRenderingContext2D)
	fun clip(context: CanvasRenderingContext2D, drawings: ()->Unit)
	
	val bounds: Rect    // 領域
}

// 円/円弧
class BasicFigureArc(val center: Vector, val radius: Double, val startAngle: Double = 0.0, val endAngle: Double = Math.PI * 2, val clockwise: Boolean = true): BPPath {
	
	fun path(context: CanvasRenderingContext2D) {
		context.arc(this.center.x, this.center.y, this.radius, this.startAngle, this.endAngle, !this.clockwise)
	}
	
	override fun fill(context: CanvasRenderingContext2D) {
		context.beginPath()
		this.path(context)
		context.fill()
	}
	
	override fun stroke(context: CanvasRenderingContext2D) {
		context.beginPath()
		this.path(context)
		context.stroke()
	}
	
	override fun clip(context: CanvasRenderingContext2D, drawings: ()->Unit) {
		context.save()
		context.beginPath()
		this.path(context)
		context.clip()
		drawings()
		context.restore()
	}
	
	override val bounds = Rect(
			top = center.y - radius,
			right = center.x + radius,
			bottom = center.y + radius,
			left = center.x - radius
	)
	
	// 円を4点のベジェ曲線で表す
	fun toBezierPoints(): List<BezierPoint> {
		
		val a = 0.5522847
		val result = mutableListOf<BezierPoint>()
		
		for(i in 0 until 4) {
			val angle = Math.PI*2 * i/4
			val anchor = this.center + Vector(radius = this.radius, theta = angle)
			val handle1 = anchor + Vector(radius = this.radius * a, theta = angle - Math.PI*2 * 1/4)
			val handle2 = anchor + Vector(radius = this.radius * a, theta = angle + Math.PI*2 * 1/4)
			val bezierPoint = BezierPoint(anchor = anchor, handle1 = handle1, handle2 = handle2)
			result.add(bezierPoint)
		}
		
		return result
	}
}


// 線のパス
class BasicFigureLine(val points: List<Vector>, val closedPath: Boolean = false): BPPath {
	
	constructor(begin: Vector, end: Vector): this(points = listOf(begin, end))
	
	fun path(context: CanvasRenderingContext2D) {
		
		context.moveTo(this.points.first().x, this.points.first().y)
		
		for(i in 1 until points.count()) {
			context.lineTo(this.points[i].x, this.points[i].y)
		}
		
		if(closedPath) context.closePath()
	}
	
	override fun stroke(context: CanvasRenderingContext2D) {
		context.beginPath()
		this.path(context)
		context.stroke()
	}
	
	override fun fill(context: CanvasRenderingContext2D) {
		context.beginPath()
		this.path(context)
		context.fill()
	}
	
	override fun clip(context: CanvasRenderingContext2D, drawings: ()->Unit) {
		context.save()
		context.beginPath()
		this.path(context)
		context.clip()
		drawings()
		context.restore()
	}
	
	override val bounds: Rect
		get() {
			if(points.isEmpty()) return Rect(0.0, 0.0, 0.0, 0.0)
			
			return Rect(
					top = points.minBy { it.y }!!.y,
					right = points.maxBy { it.x }!!.x,
					bottom = points.maxBy { it.y }!!.y,
					left = points.minBy { it.x }!!.x
			)
		}
}


// ベジェ曲線
class BezierPoint(var anchor: Vector, var handle1: Vector, var handle2: Vector)
/*class BasicFigureBezier(val points: List<BezierPoint>, val closedPath: Boolean = false): BPPath {
	
	fun path(context: CanvasRenderingContext2D) {
		
		context.moveTo(points.first().anchor.x, points.first().anchor.y)
		
		for(i in 1 until points.count()) {
			context.bezierCurveTo(
					points[i - 1].handle2.x, points[i - 1].handle2.y,
					points[i].handle1.x, points[i].handle1.y,
					points[i].anchor.x, points[i].anchor.y
			)
		}
		
		if(closedPath) {
			// 最後と最初を繋ぐ
			context.bezierCurveTo(
					points.last().handle2.x, points.last().handle2.y,
					points.first().handle1.x, points.first().handle1.y,
					points.first().anchor.x, points.first().anchor.y
			)
		}
	}
	
	override fun stroke(context: CanvasRenderingContext2D) {
		context.beginPath()
		this.path(context)
		context.stroke()
	}
	
	override fun fill(context: CanvasRenderingContext2D) {
		context.beginPath()
		this.path(context)
		context.fill()
	}
	
	override fun clip(context: CanvasRenderingContext2D, drawings: ()->Unit) {
		context.save()
		context.beginPath()
		this.path(context)
		context.clip()
		drawings()
		context.restore()
	}
}*/


// 正多角形
/*
	rotate: Angle // ラジアン（元は0%〜100%）
*/
class BasicFigureRegularPolygon(val center: Vector, val radius: Double, val numVertex: Int, val rotate: Double): BPPath {
	
	constructor(center: Vector, radius: Double, numVertex: Int, rotate: Angle): this(center, radius, numVertex, rotate.radian)
	
	fun points(): List<Vector> {
		
		var result = mutableListOf<Vector>()
		
		for (i in 0 until this.numVertex) {
			val frac = i.toDouble() / this.numVertex.toDouble()
			val angle = Math.PI * 2 * frac + rotate
			val point = center + Vector(radius = radius, theta = angle)
			result.add(point)
		}
		
		return result
	}
	
	override fun stroke(context: CanvasRenderingContext2D) {
		BasicFigureLine(this.points(), closedPath = true).stroke(context)
	}
	
	override fun fill(context: CanvasRenderingContext2D) {
		BasicFigureLine(this.points(), closedPath = true).fill(context)
	}
	
	override fun clip(context: CanvasRenderingContext2D, drawings: ()->Unit) {
		BasicFigureLine(this.points(), closedPath = true).clip(context, drawings)
	}
	
	override val bounds = Rect(
			top = center.y - radius,
			right = center.x + radius,
			bottom = center.y + radius,
			left = center.x - radius
	)
}


// 長方形
/*
	rotate: Angle // ラジアン（元は0%〜100%）
*/
/*class BasicFigureRectangle(val center: Vector, val width: Double, val height: Double, val rotate: Double): BPPath {
	
	fun points(): List<Vector> {
		
		val radius: Double = kotlin.math.sqrt((width / 2) * (width / 2) + (height / 2) * (height / 2))
		val thetaTop = kotlin.math.asin(height / 2 / radius) * 2
		val thetaRight = kotlin.math.PI - thetaTop
		
		val thetas = arrayOf(
				-thetaTop/2,                // 左上
				+thetaTop/2,                // 右上
				+thetaTop/2 + thetaRight,   // 右上
				+thetaTop*3/2 + thetaRight  // 左下
		)
		
		val result = thetas.map {
			center + Vector(radius = radius, theta = it + rotate)
		}
		
		return result
	}
	
	override fun stroke(context: CanvasRenderingContext2D) {
		BasicFigureLine(this.points(), closedPath = true).stroke(context)
	}
	
	override fun fill(context: CanvasRenderingContext2D) {
		BasicFigureLine(this.points(), closedPath = true).fill(context)
	}
	
	override fun clip(context: CanvasRenderingContext2D, drawings: ()->Unit) {
		BasicFigureLine(this.points(), closedPath = true).clip(context, drawings)
	}
}
*/