import org.w3c.dom.CanvasRenderingContext2D
import org.w3c.dom.HTMLCanvasElement
import kotlin.browser.document
import kotlin.browser.window

class Canvas {
	
	val canvas: HTMLCanvasElement
	val context: CanvasRenderingContext2D
	val height: Double
	val width: Double
	
	constructor(canvas: HTMLCanvasElement, dpr: Double) {
		this.canvas = canvas
		this.context = canvas.getContext("2d") as CanvasRenderingContext2D
		this.width = this.context.canvas.width.toDouble() / dpr
		this.height = this.context.canvas.height.toDouble() / dpr
		context.setTransform(dpr, 0.0, 0.0, dpr, 0.0, 0.0)
	}
}
