import kotlin.math.max

//vein = 静脈 = 帰り
//artery = 動脈 = 行き

data class Artery(
		val canvas: Canvas,
		val draw_frame: Rect,
		val constants: Map<Int, BPValue>
)

// ParseJsonでJson形式のChunkをKotlin形式に変換する
// get_bounds_padding
abstract class Chunk(val key: Int) {
	open fun run(artery: Artery) { }
	open fun get_value(artery: Artery): BPValue? = null
	open fun get_values(): Map<String, Any> = mapOf()
	open val draw_range_radius: Double = 0.0
	open val integer_range: IntRange = 0..0
}

class FunctionChunk(
		key: Int,
		val func: ChunkFunction<*, *>,
		val inputs: Map<String, Chunk?>,
		val joint: Chunk?,
		val scope: Chunk?
): Chunk(key) {
	
	// 引数の値を固定させる
	fun get_valued_args(artery: Artery): Map<String, BPValue> {
		return inputs.map {
			(name, chunk) ->
			val value = chunk?.get_value(artery) ?: throw Error("get_valueの値がnullです")
			Pair(name, value)
		}.toMap()
	}
	
	override fun get_value(artery: Artery): BPValue? {
		return func.get_value(artery, get_valued_args(artery))
	}
	
	override fun run(artery: Artery) {
		
		var new_artery: Artery
		
		// 定数を保存
		val valued_args = get_valued_args(artery)
		
		if(func.is_accept_scope) {
			func.do_command(key, artery, valued_args, scope)
			new_artery = artery.copy()
			
		} else {
			
			val value = func.get_value(artery, valued_args)
			val new_constants = artery.constants.toMutableMap()
			new_constants.set(key, value)
			new_artery = artery.copy(constants = new_constants)
			func.do_command(key, artery, valued_args, null)
		}
		
		joint?.run(new_artery)
	}
	
	override val draw_range_radius: Double
		get() = max(func.get_draw_range_radius(inputs), joint?.draw_range_radius ?: 0.0)
	
	
	override val integer_range: IntRange
		get() = func.get_integer_range2(inputs)
}

class LiteralChunk(
		key: Int,
		val literal_type: String,
		val value: Any
): Chunk(key) {
	
	override fun get_value(artery: Artery): BPValue? {
		
		val value = when(literal_type) {
			"INTEGER" -> value as BPInteger
			"COLOR" -> BPColor(value as Int)
			"ANGLE" -> BPAngle(value as Int)
			else -> throw Exception("literalに予期せぬ型が渡されました")
		}
		
		return BPValue(value)
	}
	
	override val integer_range: IntRange
		get() {
			if(literal_type === "INTEGER") return (value as BPInteger)..(value as BPInteger)
			return 0..0
		}
}

class ConstantChunk(
		key: Int,
		val origin_key: Int,
		val branch: List<String>
): Chunk(key) {
	
	override fun get_value(artery: Artery): BPValue? {
		return artery.constants[origin_key]!!
	}
}
