import chunk.*

val chunk_functions: List<ChunkFunction<*, *>> = listOf(
		Fill(),
		Stroke(),
		CreateVector(),
		BlurIntegerMinMax(),
		Repeat(),
		BlurAngleVibrate(),
		Plus(),
		PlusAngle(),
		CompletelyRandomAngle(),
		Circle(),
		RegularPolygon(),
		StraightLine(),
		SquareGrid(),
		RectangleGrid(),
		Clipper()
)
