
import org.w3c.dom.HTMLCanvasElement
import kotlin.browser.window
import kotlin.js.Json

// JS側からjsonを受け取って模様に変換
class JsonToPattern() {
	
	private lateinit var canvas: Canvas
	
	@JsName("useCanvas")
	fun useCanvas(canvas: HTMLCanvasElement, dpr: Double = window.devicePixelRatio) {
		this.canvas = Canvas(canvas, dpr)
	}
	
	@JsName("draw")
	fun draw(artboard: Array<Json>): Boolean {
		
		val artboard_copy = JSON.parse<Array<Json>>(JSON.stringify(artboard.copyOf()))
		val is_all_valid = Linter.validateChunks(artboard_copy)
		if(!is_all_valid) return false
		
		val start = artboard.find { it["func_name"] == "start" }!!
		val start_joint = start["joint"] as? Json
		
		try {
			val chunk = decodeChunk(chunk_json = start_joint!!)
			
			canvas.context.save()
			canvas.context.fillStyle = BPColor.white
			canvas.context.fillRect(0.0, 0.0, canvas.width, canvas.height)
			canvas.context.restore()
			
			chunk?.run(Artery(
					canvas,
					Rect(top = 0.0, right = canvas.width, bottom = canvas.height, left = 0.0),
					mapOf()
			))
			
		} catch (e: Exception) {
			console.log("描画に失敗しました")
			throw e
		}
		
		return true
	}
}

@JsName("getChunksList")
fun getChunksList(): Array<Any> {
	return chunk_functions.toTypedArray()
}

enum class Category(val rawValue: String) {
	INTEGER("integer"),
	VECTOR("vector"),
	DRAW("draw"),
	FIGURE("figure"),
	REALNUM("realnum"),
	ANGLE("angle"),
	COLOR("color");
}
