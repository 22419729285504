package chunk

import Vector
import BasicFigureArc
import ChunkFunction
import Blur
import BasicFigureRegularPolygon
import Angle
import kotlin.math.PI
import GeoLine
import LintVein
import kotlin.reflect.KClass
import BPPath
import BPAngle
import BPColor
import BPDraw
import BPInteger
import BPVector
import BPValue
import Chunk

class Fill: ChunkFunction<Fill.Inputs, BPDraw>(
		func_name = "fill",
		category = Category.DRAW,
		arg_class = Inputs::class
) {
	class Inputs(
			val figure: BPPath,
			val color: BPColor
	)
	
	override val inputs_structure = listOf(
			Pair("figure", BPPath::class),
			Pair("color", BPColor::class)
	)
	
	override val outputs_structure: List<Pair<String, KClass<*>>> = listOf()
	
	override fun outputs(inputs: Inputs): BPDraw = BPDraw()  // 本当は何も返す必要はない
	
	// コマンドモードで実行する
	override fun command(key: Int, inputs: Inputs, scope: Chunk?) {
		val context = this.artery.canvas.context
		context.fillStyle = inputs.color.hex_color
		inputs.figure.fill(context)
	}
	
	override fun get_draw_range_radius(args: Map<String, Chunk?>): Double {
		return args["figure"]?.draw_range_radius ?: 0.0
	}
}

class Stroke: ChunkFunction<Stroke.Inputs, BPDraw>(
		func_name = "stroke",
		category = Category.DRAW,
		arg_class = Inputs::class
) {
	class Inputs(
			val figure: BPPath,
			val line_width: BPInteger,
			val color: BPColor
	)
	
	override val inputs_structure = listOf(
			Pair("figure", BPPath::class),
			Pair("line_width", BPInteger::class),
			Pair("color", BPColor::class)
	)
	
	override val outputs_structure: List<Pair<String, KClass<*>>> = listOf()
	
	override fun outputs(inputs: Inputs): BPDraw = BPDraw()  // 本当は何も返す必要はない
	
	// コマンドモードで実行する
	override fun command(key: Int, inputs: Inputs, scope: Chunk?) {
		val context = this.artery.canvas.context
		context.strokeStyle = inputs.color.hex_color
		context.lineWidth = inputs.line_width.toDouble()
		inputs.figure.stroke(context)
	}
	
	override fun validate_inputs(inputs: Map<String, LintVein>): List<String> {
		val invalids = mutableListOf<String>()
		if(inputs["line_width"]!!.integer_range!!.start < 1) invalids += "line_width"
		return invalids
	}
	
	override fun get_draw_range_radius(args: Map<String, Chunk?>): Double {
		val figure_radius = args["figure"]?.draw_range_radius ?: 0.0
		val line_max_width = args["line_width"]?.integer_range?.endInclusive?.toDouble() ?: 0.0
		return figure_radius + line_max_width
	}
}

class CreateVector: ChunkFunction<CreateVector.Inputs, BPVector>(
		func_name = "create_vector",
		category = Category.VECTOR,
		arg_class = Inputs::class
) {
	class Inputs(
			val x: BPInteger,
			val y: BPInteger
	)
	
	override val inputs_structure = listOf(
			Pair("x", BPInteger::class),
			Pair("y", BPInteger::class)
	)
	
	override val outputs_structure = listOf(
			Pair("vector", BPVector::class)
	)
	
	override fun outputs(inputs: Inputs) = BPVector(x = inputs.x.toDouble(), y = inputs.y.toDouble())
}

class BlurIntegerMinMax: ChunkFunction<BlurIntegerMinMax.Inputs, BPInteger>(
		func_name = "blur_integer_min_max",
		category = Category.INTEGER,
		arg_class = Inputs::class
) {
	class Inputs(
			val min: BPInteger,
			val max: BPInteger
	)
	
	override val inputs_structure = listOf(
			Pair("min", BPInteger::class),
			Pair("max", BPInteger::class)
	)
	
	override val outputs_structure = listOf(
			Pair("integer", BPInteger::class)
	)
	
	override fun outputs(inputs: Inputs) = Blur(min = inputs.min, max = inputs.max).toInt()
	
	override fun get_integer_range(arg: Map<String, LintVein>): IntRange {
		
		val min = arg["min"]!!.integer_range!!.start
		val max = arg["max"]!!.integer_range!!.endInclusive
		
		return min..max
	}
	
	override fun get_integer_range2(arg: Map<String, Chunk?>): IntRange {
		val min = arg["min"]!!.integer_range.start
		val max = arg["max"]!!.integer_range.endInclusive
		return min..max
	}
}

class Repeat: ChunkFunction<Repeat.Inputs, BPInteger>(
		func_name = "repeat",
		category = Category.INTEGER,
		arg_class = Inputs::class
) {
	class Inputs(
			val times: BPInteger
	)
	
	override val inputs_structure = listOf(
			Pair("times", BPInteger::class)
	)
	
	override val outputs_structure: List<Pair<String, KClass<*>>> = listOf()
	
	override fun outputs(inputs: Inputs): BPInteger {
		return inputs.times    // 本当は何も返す必要なし
	}
	
	override fun validate_inputs(inputs: Map<String, LintVein>): List<String> {
		val invalids = mutableListOf<String>()
		if(inputs["times"]!!.integer_range!!.start < 1) invalids += "times"
		return invalids
	}
	
	override fun get_integer_range(arg: Map<String, LintVein>): IntRange {
		return arg["block"]!!.integer_range!!
	}
	
	// コマンドモードで実行する
	override val is_accept_scope = true
	override fun command(key: Int, inputs: Inputs, scope: Chunk?) {
		scope ?: return
		
		for (i in 0 until inputs.times) {
			scope.run(this.artery)
		}
	}
}

// 線形ランダム
class BlurAngleVibrate: ChunkFunction<BlurAngleVibrate.Inputs, BPAngle>(
		func_name = "blur_angle_vibrate",
		category = Category.ANGLE,
		arg_class = Inputs::class
) {
	class Inputs(
			val base_angle: BPAngle,
			val size: BPAngle
	)
	
	override val inputs_structure = listOf(
			Pair("base_angle", BPAngle::class),
			Pair("size", BPAngle::class)
	)
	
	override val outputs_structure = listOf(
			Pair("angle", BPAngle::class)
	)
	
	override fun outputs(inputs: Inputs) = BPAngle(value = Blur(center = inputs.base_angle.value, radius = inputs.size.value).toInt())
}

class Plus: ChunkFunction<Plus.Inputs, BPVector>(
		func_name = "plus",
		category = Category.VECTOR,
		arg_class = Inputs::class
) {
	class Inputs(
			val p1: BPVector,
			val p2: BPVector
	)
	
	override val inputs_structure = listOf(
			Pair("p1", BPVector::class),
			Pair("p2", BPVector::class)
	)
	
	override val outputs_structure = listOf(
			Pair("vector", BPVector::class)
	)
	
	override fun outputs(inputs: Inputs) = inputs.p1 + inputs.p2
}


class PlusAngle: ChunkFunction<PlusAngle.Inputs, BPAngle>(
		func_name = "plus-angle",
		category = Category.ANGLE,
		arg_class = Inputs::class
) {
	class Inputs(
			val p1: BPAngle,
			val p2: BPAngle
	)
	
	override val inputs_structure = listOf(
			Pair("p1", BPAngle::class),
			Pair("p2", BPAngle::class)
	)
	
	override val outputs_structure = listOf(
			Pair("angle", BPAngle::class)
	)
	
	override fun outputs(inputs: Inputs) = inputs.p1 + inputs.p2
}


class CompletelyRandomAngle: ChunkFunction<CompletelyRandomAngle.Inputs, BPAngle>(
		func_name = "completely_random_angle",
		category = Category.ANGLE,
		arg_class = Inputs::class
) {
	class Inputs
	override val inputs_structure = listOf<Pair<String, KClass<*>>>()
	
	override val outputs_structure = listOf(
			Pair("angle", BPAngle::class)
	)
	
	override fun outputs(inputs: Inputs) = BPAngle(value = Blur(min = 0, max = 360).toInt())
}

class Circle: ChunkFunction<Circle.Inputs, BPPath>(
		func_name = "circle",
		category = Category.FIGURE,
		arg_class = Inputs::class
) {
	class Inputs(
			val center: BPVector,
			val radius: BPInteger
	)
	
	override val inputs_structure = listOf(
			Pair("center", BPVector::class),
			Pair("radius", BPInteger::class)
	)
	
	override val outputs_structure = listOf(
			Pair("path", BPPath::class)
	)
	
	override fun outputs(inputs: Inputs) = BasicFigureArc(
			center = inputs.center,
			radius = inputs.radius.toDouble()
	)
	
	override fun validate_inputs(inputs: Map<String, LintVein>): List<String> {
		val invalids = mutableListOf<String>()
		if(inputs["radius"]!!.integer_range!!.start < 1) invalids += "radius"
		return invalids
	}
	
	override fun get_draw_range_radius(args: Map<String, Chunk?>): Double {
		val max_radius = args["radius"]?.integer_range?.endInclusive?.toDouble() ?: 0.0
		return max_radius
	}
}

class RegularPolygon: ChunkFunction<RegularPolygon.Inputs, BPPath>(
		func_name = "regular_polygon",
		category = Category.FIGURE,
		arg_class = Inputs::class
) {
	class Inputs(
			val center: BPVector,
			val radius: BPInteger,
			val num_vertex: BPInteger,
			val angle: BPAngle
	)
	
	override val inputs_structure = listOf(
			Pair("center", BPVector::class),
			Pair("radius", BPInteger::class),
			Pair("num_vertex", BPInteger::class),
			Pair("angle", BPAngle::class)
	)
	
	override val outputs_structure = listOf(
			Pair("path", BPPath::class)
	)
	
	override fun outputs(inputs: Inputs) = BasicFigureRegularPolygon(
			inputs.center,
			inputs.radius.toDouble(),
			inputs.num_vertex,
			rotate = Angle(frac = inputs.angle.value.toDouble() / 360)
	)
	
	override fun validate_inputs(inputs: Map<String, LintVein>): List<String> {
		val invalids = mutableListOf<String>()
		if(inputs["num_vertex"]!!.integer_range!!.start < 3) invalids += "num_vertex"
		if(inputs["radius"]!!.integer_range!!.start < 1) invalids += "radius"
		return invalids
	}
	
	override fun get_draw_range_radius(args: Map<String, Chunk?>): Double {
		val max_radius = args["radius"]?.integer_range?.endInclusive?.toDouble() ?: 0.0
		return max_radius
	}
}

class StraightLine: ChunkFunction<StraightLine.Inputs, BPPath>(
		func_name = "straight_line",
		category = Category.FIGURE,
		arg_class = Inputs::class
) {
	class Inputs(
			val center: Vector,
			val angle: BPAngle
	)
	
	override val inputs_structure = listOf(
			Pair("center", BPVector::class),
			Pair("angle", BPAngle::class)
	)
	
	override val outputs_structure = listOf(
			Pair("path", BPPath::class)
	)
	
	override fun outputs(inputs: Inputs) =
			GeoLine(point = inputs.center, theta = inputs.angle.value.toDouble() / 180 * PI)
					.toLineSegment(this.artery.draw_frame)
					.toPath()
}

class SquareGrid: ChunkFunction<SquareGrid.Inputs, BPVector>(
		func_name = "square_grid",
		category = Category.VECTOR,
		arg_class = Inputs::class
) {
	class Inputs(
			val interval: BPInteger
	)
	
	override val inputs_structure = listOf(
			Pair("interval", BPInteger::class)
	)
	
	override val outputs_structure = listOf(
			Pair("vector", BPVector::class)
	)
	
	override fun outputs(inputs: Inputs): BPVector = BPVector()
	
	override fun validate_inputs(inputs: Map<String, LintVein>): List<String> {
		val invalids = mutableListOf<String>()
		if(inputs["interval"]!!.integer_range!!.start < 10) invalids += "interval"
		return invalids
	}
	
	// コマンドモードで実行する
	override val is_accept_scope = true
	override fun command(key: Int, inputs: Inputs, scope: Chunk?) {
		scope ?: return
		
		val range = this.artery.draw_frame.padding(scope.draw_range_radius)
		
		// 描画範囲の中心点から点対象とするため
		val offset_x = (range.width() / 2) % inputs.interval
		val offset_y = (range.height() / 2) % inputs.interval
		
		val top = (offset_y + range.top).toInt()
		val right = range.right.toInt()
		val bottom = range.bottom.toInt()
		val left = (offset_x + range.left).toInt()
		
		for (x in left..right step inputs.interval) {
			for (y in top..bottom step inputs.interval) {
				val vector = Vector(x = x.toDouble(), y = y.toDouble())
				val value = BPValue(value = vector)
				
				// 定数にセット
				val new_constants = artery.constants.toMutableMap()
				new_constants.set(key, value)
				val new_artery = artery.copy(constants = new_constants)
				
				scope.run(new_artery)
			}
		}
	}
}

class RectangleGrid: ChunkFunction<RectangleGrid.Inputs, BPVector>(
		func_name = "rectangle_grid",
		category = Category.VECTOR,
		arg_class = Inputs::class
) {
	class Inputs(
			val interval_x: BPInteger,
			val interval_y: BPInteger
	)
	
	override val inputs_structure = listOf(
			Pair("interval_x", BPInteger::class),
			Pair("interval_y", BPInteger::class)
	)
	
	override val outputs_structure = listOf(
			Pair("vector", BPVector::class)
	)
	
	override fun outputs(inputs: Inputs): BPVector = BPVector()
	
	override fun validate_inputs(inputs: Map<String, LintVein>): List<String> {
		val invalids = mutableListOf<String>()
		if(inputs["interval_x"]!!.integer_range!!.start < 10) invalids += "interval_x"
		if(inputs["interval_y"]!!.integer_range!!.start < 10) invalids += "interval_y"
		return invalids
	}
	
	// コマンドモードで実行する
	override val is_accept_scope = true
	override fun command(key: Int, inputs: Inputs, scope: Chunk?) {
		scope ?: return
		
		val range = this.artery.draw_frame.padding(scope.draw_range_radius)
		
		// 描画範囲の中心点から点対象とするため
		val offset_x = (range.width() / 2) % inputs.interval_x
		val offset_y = (range.height() / 2) % inputs.interval_y
		
		val top = (offset_y + range.top).toInt()
		val right = range.right.toInt()
		val bottom = range.bottom.toInt()
		val left = (offset_x + range.left).toInt()
		
		for (x in left..right step inputs.interval_x) {
			for (y in top..bottom step inputs.interval_y) {
				val vector = Vector(x = x.toDouble(), y = y.toDouble())
				val value = BPValue(value = vector)
				
				// 定数にセット
				val new_constants = artery.constants.toMutableMap()
				new_constants.set(key, value)
				val new_artery = artery.copy(constants = new_constants)
				
				scope.run(new_artery)
			}
		}
	}
}

class Clipper: ChunkFunction<Clipper.Inputs, BPDraw>(
		func_name = "clipper",
		category = Category.DRAW,
		arg_class = Inputs::class
) {
	class Inputs(
			val figure: BPPath
	)
	
	override val inputs_structure = listOf(
			Pair("figure", BPPath::class)
	)
	
	override val outputs_structure: List<Pair<String, KClass<*>>> = listOf()
	
	override fun outputs(inputs: Inputs): BPDraw = BPDraw()  // 本当は何も返す必要はない
	
	// コマンドモードで実行する
	override val is_accept_scope = true
	override fun command(key: Int, inputs: Inputs, scope: Chunk?) {
		scope ?: return
		
		val context = this.artery.canvas.context
		val draw_frame = inputs.figure.bounds
		val new_artery = artery.copy(draw_frame = draw_frame)
		
		inputs.figure.clip(context) {
			scope.run(new_artery)
		}
	}
	
	override fun get_draw_range_radius(args: Map<String, Chunk?>): Double {
		return args["figure"]?.draw_range_radius ?: 0.0
	}
}
