

import org.w3c.dom.CanvasRenderingContext2D
import kotlin.js.Math

// 数学的直線
class GeoLine {
	var p1: Vector
	var p2: Vector
	
	// 2点を通る直線
	constructor(p1: Vector, p2: Vector) {
		this.p1 = p1
		this.p2 = p2
	}
	
	// y = a * x + b
	constructor(a: Double, b: Double) {
		this.p1 = Vector(x = 0.0, y = b)
		this.p2 = Vector(x = 1.0, y = a + b)
	}
	
	// x = R, y = R
	constructor(x: Double? = null, y: Double? = null) {
		if(x != null) {
			this.p1 = Vector(x = x, y = 0.0)
			this.p2 = Vector(x = x, y = 1.0)
		} else {
			this.p1 = Vector(x = 0.0, y = y)
			this.p2 = Vector(x = 1.0, y = y)
		}
	}
	
	// point & theta
	constructor(point: Vector, theta: Double) {
		this.p1 = point
		this.p2 = point + Vector(radius = 1.0, theta = theta)
	}
	
	
	
	// 傾き
	fun m(): Double? {
		if (this.p2.x == this.p1.x) return null
		return (this.p2.y - this.p1.y) / (this.p2.x - this.p1.x)
	}
	
	// 一般形 ax+by+c=0
	fun inGeneralForm(): Triple<Double, Double, Double> {
		val dx = p2.x - p1.x
		val dy = p2.y - p1.y
		
		val a = dy
		val b = -dx
		val c = -dy * p1.x + dx * p1.y
		
		return Triple(a, b, c)
	}
	
	fun getPoint(x: Double? = null, y: Double? = null): Vector? {
		
		if(x !== null) {
			if(this.m() == null) return null    // y軸と平行
			val y = (x!! - this.p1.x) * this.m()!! + this.p1.y
			return Vector(x!!, y)
		} else {
			if(this.m() == 0.0) return null    // x軸と平行
			val x = (y!! - this.p1.y) / this.m()!! + this.p1.x
			return Vector(x, y!!)
		}
	}
	
	fun translate(vector: Vector): GeoLine {
		return GeoLine(p1 = this.p1 + vector, p2 = this.p2 + vector)
	}
	
	// 自身に直交して & 特定の点を通る線
	fun orthogonalLine(throughPoint: Vector): GeoLine {
		
		return if(this.m() != null) {
			val _m = -1 / this.m()!!
			val _b = throughPoint.y - _m * throughPoint.x	// y = ax + b
			GeoLine(a = _m, b = _b)
			
		} else {
			
			GeoLine(x = throughPoint.x)
		}
	}
	
	fun toLineSegment(frame: Rect): GeoLineSegment {
		var start: Vector
		var end: Vector
		
		if(this.m() == null) {
			start = Vector(x = this.p1.x, y = frame.top)
			end = Vector(x = this.p1.x, y = frame.bottom)
		} else {
			start = this.getPoint(x = frame.left)!!
			end = this.getPoint(x = frame.right)!!
		}
		
		return GeoLineSegment(p1 = start, p2 = end)
	}
	
	fun stroke(context: CanvasRenderingContext2D, size: Rect) {
		var start: Vector
		var end: Vector
		
		if(this.m() == null) {
			start = Vector(x = this.p1.x, y = size.top)
			end = Vector(x = this.p1.x, y = size.bottom)
		} else {
			
			start = this.getPoint(x = size.left)!!
			end = this.getPoint(x = size.right)!!
		}
		
		BasicFigureLine(listOf(start, end)).stroke(context)
	}
	
	// 直線との交点
	fun intersectionWith(line: GeoLine): Vector? {
		val a = this.inGeneralForm()
		val b = line.inGeneralForm()
		
		if(a.first * b.second == b.first * a.second) return null
		val denom = a.first * b.second - b.first * a.second
		
		val vector = Vector(
				x = (a.second * b.third - b.second * a.third) / denom,
				y = (b.first * a.third - a.first * b.third) / denom
		)
		
		return vector
	}
}

class GeoLineSegment(var p1: Vector, var p2: Vector) {
	
	fun inLine(): GeoLine {
		return GeoLine(p1 = p1, p2 = p2)
	}
	
	fun intersectionWith(line: GeoLine): Vector? {
		val inter = this.inLine().intersectionWith(line)
		if(inter == null) return null
		
		// 線分の範囲内にあるか否か
		if(Math.abs(this.p1.x - this.p2.x) > 1.0) {
			val rangeLeft = listOf(this.p1.x, this.p2.x).min()!!
			val rangeRight = listOf(this.p1.x, this.p2.x).max()!!
			if(rangeLeft <= inter!!.x && inter!!.x <= rangeRight) return inter!!
			
		} else {
			val rangeTop = listOf(this.p1.y, this.p2.y).min()!!
			val rangeBottom = listOf(this.p1.y, this.p2.y).max()!!
			if(rangeTop <= inter!!.y && inter!!.y <= rangeBottom) return inter!!
		}
		
		return null
	}
	
	fun stroke(context: CanvasRenderingContext2D) {
		BasicFigureLine(listOf(p1, p2)).stroke(context)
	}
	
	fun toPath(): BPPath {
		return BasicFigureLine(listOf(p1, p2))
	}
}


// 数学的円
class GeoCircle {
	
	var center: Vector
	var radius: Double
	
	constructor(center: Vector, radius: Double) {
		this.center = center
		this.radius = radius
	}
	
	fun stroke(context: CanvasRenderingContext2D) {
		BasicFigureArc(center = center, radius = this.radius).stroke(context)
	}
	
	fun fill(context: CanvasRenderingContext2D) {
		BasicFigureArc(center = center, radius = this.radius).fill(context)
	}
	
	fun isCollide(target: GeoCircle): Boolean {
		val gap = target.center - this.center
		val distance = Math.sqrt(gap.x * gap.x + gap.y * gap.y)
		return distance < this.radius + target.radius
	}
	
	override operator fun equals(given: Any?): Boolean {
		if(given == null || given !is GeoCircle) return false
		return this.center == given.center && this.radius == given.radius
	}
}


// 数学的多角形
class GeoPolygon {
	
	var vertexes: List<Vector>
	
	constructor(vertexes: List<Vector>) {
		this.vertexes = vertexes
	}
	
	fun stroke(context: CanvasRenderingContext2D) {
		BasicFigureLine(points = this.vertexes, closedPath = true).stroke(context)
	}
	
	fun fill(context: CanvasRenderingContext2D) {
		BasicFigureLine(points = this.vertexes, closedPath = true).fill(context)
	}
	
	fun clip(context: CanvasRenderingContext2D, drawings: ()->Unit) {
		BasicFigureLine(points = this.vertexes, closedPath = true).clip(context, drawings)
	}
	
	// 自身を線分の配列で表す
	fun toGeoLineSegments(): List<GeoLineSegment> {
		
		val result = mutableListOf<GeoLineSegment>()
		
		for(i in 0 until this.vertexes.count() - 1) {
			result.add(GeoLineSegment(p1 = this.vertexes[i], p2 = this.vertexes[i + 1]))
		}
		
		result.add(GeoLineSegment(p1 = this.vertexes.last(), p2 = this.vertexes.first()))
		
		return result
	}
	
	// 交点
	fun intersectionsWith(line: GeoLine): List<Vector> {
		val sides = this.toGeoLineSegments()
		val intersections = sides.mapNotNull { it.intersectionWith(line = line) }
		return intersections
	}
	
	// 多角形を直線で分割（凹多角形は非対応）
	fun splitBy(line: GeoLine): List<GeoPolygon> {
		// （図解した方が理解しやすい）
		
		val sides = this.toGeoLineSegments()
		
		// 多角形の数 x 多角形の線分 = [[線分]]
		val polygonsSides = mutableListOf(mutableListOf<GeoLineSegment>())
		sides.forEachIndexed {
			index, lineSegment ->
			
			val intersection = lineSegment.intersectionWith(line = line)
			
			if(intersection == null) {
				polygonsSides.last().add(lineSegment)
			} else {
				
				// この線分内に交点がある場合、ここで多角形を分割する
				polygonsSides.last().add(
						GeoLineSegment(p1 = lineSegment.p1, p2 = intersection)
				)
				
				polygonsSides.add(mutableListOf<GeoLineSegment>())
				
				polygonsSides.last().add(
						GeoLineSegment(p1 = intersection, p2 = lineSegment.p2)
				)
			}
		}
		
		if(polygonsSides.count() == 1 ) return listOf(this)
		
		// 最後の線分と最初の線分をくっつける
		polygonsSides.last().addAll(polygonsSides.first())
		polygonsSides.removeAt(0)
		
		val polygons = polygonsSides.map {
			sides ->
			val vertexes = sides.map { it.p1 }.plus(sides.last().p2)
			GeoPolygon(vertexes = vertexes)
		}
		
		return polygons
	}
	
	// 多角形を複数の直線で分割
	fun splitBy(lines: List<GeoLine>): List<GeoPolygon> {
		
		var polygons = listOf(this)
		
		lines.forEach {
			line ->
			polygons = polygons.fold(listOf<GeoPolygon>()) {
				sum, polygon -> sum.plus(polygon.splitBy(line = line))
			}
		}
		
		return polygons
	}
}



/* * * Geometric Calculations * * */

// 交点を求める
//fun GeometricIntersects(circle: GeoCircle, line: GeoLine): List<Vector> {
//	// circle: (x-p)^2 + (x-q)^2 = r^2
//	// line: y = ax + b
//
//	val a = line.m()!!  // might be occur null unwrap error
//	val b = line.p1.y - a * line.p1.x	// y = ax + b
//	val p = circle.center.x
//	val q = circle.center.y
//	val r = circle.radius
//
//	val xs = GeometricQuadraticFormula(1 + a*a, 2*a*b -2*a*q -2*p, p*p + (b-q)*(b-q) - r*r)	// 交点のX座標たち
//
//	return xs.map {x -> line.getPoint(x = x)!!}  // might be occur null unwrap error
//}

// 解の公式
fun GeometricQuadraticFormula(a: Double, b: Double, c: Double): List<Double> {
	return listOf(
		(-b + Math.sqrt(b*b -4*a*c)) / (2*a),
		(-b - Math.sqrt(b*b -4*a*c)) / (2*a)
	)
}

// 何度にあるか判定
/*
GeometricAngle({
	origin: Point	// 中心
	zeroRad: Point	// ゼロ度の方向
	target: Point	// 求める角度の点
	clockwise: Bool	// 時計回り（未実装）
})
*/
//fun GeometricAngle(origin: Vector, zeroRad: Vector, target: Vector): Angle {
//	val OA = (zeroRad - origin).toPolar()
//	val OB = (target - origin).toPolar()
//	val angle = (OB.theta - OA.theta + Math.PI * 4) % (Math.PI * 2)
//	return Angle(rad = angle)
//}
//
//
//fun GeometricDistance(p1: Vector, p2: Vector): Double {
//	val dx = p1.x - p2.x
//	val dy = p1.y - p2.y
//	return Math.sqrt(dx * dx + dy * dy)
//}
//
//fun GeometricLimit(value: Double, min: Double? = null, max: Double? = null): Double {
//	var result = value
//	if(min !== null && result < min) result = min
//	if(max !== null && result > max) result = max
//	return result
//}
