import kotlin.reflect.KClass

abstract class ChunkFunction<T: Any, U: Any>(
		val func_name: String,
		val category: Category,
		val arg_class: KClass<T>
) {
	open fun validate_inputs(inputs: Map<String, LintVein>): List<String> = listOf()
	
	lateinit var artery: Artery
	
	abstract val inputs_structure: List<Pair<String, KClass<*>>>
	open val outputs_structure: List<Pair<String, KClass<*>>> = listOf() // 戻り値の構造（定数チャンク用）
	abstract fun outputs(inputs: T): U
	
	fun arg_from_list_of_any(list: List<Any>): T {
		
		val t_constructor = this.arg_class.js
		val t_arguments = list.toTypedArray()
		
		// 「new Inputs(...list)」のポリフィル
		
		js("""
			function applyable_constructor(constructor, args) {
				
				function partial() {
					return constructor.apply(this, args)
				}
				
				if(typeof constructor.prototype === "object") {
					partial.prototype = Object.create(constructor.prototype)
				}
				
				return partial
			}
			
			var arg = applyable_constructor(t_constructor, t_arguments)
		""")

		return js("new arg")
	}
	
	class RequireInputs(val name: String, val type: String)
	
	@JsName("get_required_args")
	fun get_required_args(): Array<RequireInputs> {
		return inputs_structure.map { RequireInputs(it.first, get_BPValue_class_name(it.second)) }.toTypedArray()
	}
	
	// BPFunctionの実行準備をする
	fun get_value(artery: Artery, inputs: Map<String, BPValue>): BPValue {
		this.artery = artery
		
		val inputs_list = inputs_structure.map { inputs[it.first]!!.value }
		val inputs = arg_from_list_of_any(inputs_list)
		val value = outputs(inputs)
		
		return BPValue(value = value)
	}
	
	// コマンドモードで実行する
	fun do_command(key: Int, artery: Artery, inputs: Map<String, BPValue>, scope: Chunk?) {
		this.artery = artery
		val inputs_list = inputs_structure.map { inputs[it.first]!!.value }
		val inputs = arg_from_list_of_any(inputs_list)
		command(key, inputs, scope)
	}
	
	open val is_accept_scope = false
	open fun command(key: Int, inputs: T, scope: Chunk?) { }
	
	// BPFunctionを実行する
	fun validate(inputs: Map<String, LintVein?>, push_invalid: (Linter.Invalidness) -> Unit): LintVein? {
		
		validate_inputs_type(inputs, push_invalid)
		
		try {
			// 型の正確性が保証された引数リスト
			val perfect_args = inputs.mapNotNull { it.key to it.value!! }.toMap()
			
			// 引数の中身をチェック
			val invalid_args = this.validate_inputs(perfect_args)
			
			invalid_args.forEach {
				arg_name ->
				push_invalid(Linter.Invalidness(arg_name, Linter.Invalidness.Type.ARG_VALUE, "不正な引数の値"))
			}
			
			return LintVein(
					value_type = outputs_structure.firstOrNull()?.second ?: Unit::class,
					integer_range = get_integer_range(perfect_args)
			)
			
		} catch(e: Exception) {
			
			return LintVein(
					value_type = outputs_structure.firstOrNull()?.second ?: Unit::class,
					integer_range = null
			)
		}
	}
	
	fun validate_inputs_type(inputs: Map<String, LintVein?>, push_invalid: (Linter.Invalidness) -> Unit) {
		
		inputs_structure.forEach {
			(require_arg_name, require_arg_type) ->
			val vein = inputs[require_arg_name]
			
			if(vein === null) {
				val reason = "関数「${func_name}」の引数「${require_arg_name}」が指定されていません。「${require_arg_type}」型の引数を指定してください。"
				push_invalid(Linter.Invalidness(require_arg_name, Linter.Invalidness.Type.ARG_EMPTY, reason))
				return@forEach
			}
			
			if(vein.value_type !== require_arg_type) {
				val reason = "関数「${func_name}」の引数「${require_arg_name}」に「${vein.value_type}」型が渡されています。「${require_arg_type}」型の引数を指定してください。"
				push_invalid(Linter.Invalidness(require_arg_name, Linter.Invalidness.Type.ARG_TYPE, reason))
				return@forEach
			}
		}
	}
	
	open fun get_integer_range(arg: Map<String, LintVein>): IntRange? = null
	open fun get_integer_range2(arg: Map<String, Chunk?>): IntRange = 0..0
	open fun get_draw_range_radius(args: Map<String, Chunk?>): Double = 0.0
}
