
import kotlin.js.Math

class Vector {
	var x: Double
	var y: Double
	
	companion object {
		val zero = Vector(x = 0.0, y = 0.0)
	}
	
	constructor(x: Double? = null, y: Double? = null,
	            radius: Double? = null, theta: Double? = null) {
		if(x != null) {
			this.x = x!!
			this.y = y!!
		} else {
			this.x = radius!! * Math.cos(theta!!)
			this.y = radius!! * Math.sin(theta!!)
		}
	}
	
	constructor(radius: Double, theta: Angle) {
		this.x = radius * Math.cos(theta.radian)
		this.y = radius * Math.sin(theta.radian)
	}
	
	class Polar(val radius: Double, val theta: Double)
	
	fun toPolar(): Polar {
		val radius = Math.sqrt(this.x * this.x + this.y * this.y)
		if(radius == 0.0) throw Error("距離がゼロです")
		
		val cos = this.x / radius
		val sin = this.y / radius
		
		val signX = if(cos > 0) '+' else if(cos < 0) '-' else '0'
		val signY = if(sin > 0) '+' else if(sin < 0) '-' else '0'
		val signs = "($signX,$signY)"
		
		var theta = Math.abs(Math.asin(sin))
		
		theta = when(signs) {
			"(+,0)" -> Math.PI * 0/2
			"(0,+)" -> Math.PI * 1/2
			"(-,0)" -> Math.PI * 2/2
			"(0,-)" -> Math.PI * 3/2
			"(+,+)" -> theta
			"(-,+)" -> Math.PI - theta
			"(-,-)" -> theta + Math.PI
			"(+,-)" -> Math.PI * 2 - theta
			"(0,0)" -> return Polar(0.0, 0.0)
			else -> throw Error("不明な角度の指定: ${signs}")
		}
		
		return Polar(radius, theta)
	}
	
	// Operators
	operator fun plus(given: Vector): Vector {
		return Vector(x = this.x + given.x, y = this.y + given.y)
	}
	
	operator fun minus(given: Vector): Vector {
		return Vector(x = this.x - given.x, y = this.y - given.y)
	}
	
	override operator fun equals(given: Any?): Boolean {
		if(given == null || given !is Vector) return false
		return this.x == given.x && this.y == given.y
	}
}
