
import kotlin.js.Math

class Rect {
	
	// 全て左上原点
	var left: Double
	var top: Double
	var right: Double
	var bottom: Double
	
	constructor(top: Double? = null, right: Double? = null, bottom: Double? = null, left: Double? = null,
	            x: Double? = null, y: Double? = null, width: Double? = null, height: Double? = null) {
		if(top != null) {
			this.top = top!!
			this.right = right!!
			this.bottom = bottom!!
			this.left = left!!
		} else {
			this.top = y!! - height!! / 2
			this.right = x!! + width!! / 2
			this.bottom = y!! + height!! / 2
			this.left = x!! - width!! / 2
		}
	}
	
	
	fun width(): Double {
		return this.right - this.left
	}
	
	fun height(): Double {
		return this.bottom - this.top
	}
	
	fun center(): Vector {
		return Vector(x = this.left + this.width() / 2, y = this.top + this.height() / 2)
	}
	
	// Padding like CSS
	fun padding(all: Double): Rect {
		return Rect(top = this.top - all, right = this.right + all, bottom = this.bottom + all, left = this.left - all)
	}
	
	fun padding(vert: Double = 0.0, horz: Double = 0.0): Rect {
		return Rect(top = this.top - vert, right = this.right + horz, bottom = this.bottom + vert, left = this.left - horz)
	}
	
	fun padding(top: Double = 0.0, right: Double = 0.0, bottom: Double = 0.0, left: Double = 0.0): Rect {
		return Rect(top = this.top - top, right = this.right + right, bottom = this.bottom + bottom, left = this.left - left)
	}
	
	// 矩形範囲内のランダムな点
	fun randomPoint(): Vector {
		val x = Blur(min = this.left, max = this.right).toDouble()
		val y = Blur(min = this.top, max = this.bottom).toDouble()
		return Vector(x = x, y = y)
	}
	
	fun toGeoPolygon(): GeoPolygon {
		
		val points = listOf<Vector>(
				Vector(x = this.left, y = this.top),
				Vector(x = this.right, y = this.top),
				Vector(x = this.right, y = this.bottom),
				Vector(x = this.left, y = this.bottom)
		)
		
		return GeoPolygon(vertexes = points)
	}
	
	fun contains(point: Vector): Boolean {
		return (this.left <= point.x) && (point.x < this.right) && (this.top <= point.y) && (point.y < this.bottom)
	}
}
