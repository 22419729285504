import kotlin.js.Json

fun decodeChunk(chunk_json: Json?): Chunk? {
	if(chunk_json === null) return null
	
	val chunk_type = chunk_json["chunk_type"] as String
	val key = chunk_json["key"] as? Int ?: 0    // TODO:nullはエラーさせる
	
	when(chunk_type) {
		
		// 関数型チャンク
		"function" -> {
			val func_name = chunk_json["func_name"] as String
			val args_json = chunk_json["args"] as Json
			
			// 指定された関数チャンク
			val chunk_func = chunk_functions.find { it.func_name === func_name }!!
			
			// 関数チャンクの引数を揃える
			val args = chunk_func.inputs_structure.map {
				(arg_name) ->
				Pair(arg_name, decodeChunk(chunk_json = args_json[arg_name] as Json))
			}.toMap()
			
			val joint = decodeChunk(chunk_json = chunk_json["joint"] as? Json)
			val scope = decodeChunk(chunk_json = chunk_json["scope"] as? Json)
			return FunctionChunk(key, chunk_func, args, joint, scope)
		}
		
		// リテラル型チャンク
		"literal" -> {
			val literal_type = chunk_json["literal_type"] as String
			
			(chunk_json["override"] as? Json)?.let {
				return decodeChunk(chunk_json = it)
			}
			
			return LiteralChunk(key, literal_type, chunk_json["value"]!!)
		}
		
		// 定数型チャンク
		"constant" -> {
			val origin_key = chunk_json["origin_key"] as Int
			val branch = chunk_json["branch"] as Array<String>
			return ConstantChunk(key, origin_key, branch.toList())
		}
	}
	
	throw Exception()
}
